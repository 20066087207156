import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import offersStore from '../../store/OffersStore';
import OffersConfirmation from './OffersConfirmation';
import { SectionTitleDivider, SectionTitle, SectionArrow } from '../Styled/SectionTitleDivider';
import { text } from '../../utils';
const StyledSectionTitleDivider = styled(SectionTitleDivider)`
  background: none;
  &:hover {
    background: none;
  }

  &:before {
    left: 0;
    width: 100%;
    display: block;
  }
`;

const ConfirmationRoomContainer = styled.div`
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
`;

const RejectedOfferSection = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ConfirmationRoomContainer>
      <StyledSectionTitleDivider open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <SectionTitle>
          <h3>{text('rejectedOffers')}</h3>
        </SectionTitle>
        <SectionArrow open={isOpen} />
      </StyledSectionTitleDivider>
      {isOpen && (
        <OffersConfirmation offers={offersStore.totallyRejectedOffers} hideConfirmationGuide />
      )}
    </ConfirmationRoomContainer>
  );
};

export default observer(RejectedOfferSection);
