import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import Modal from '../../Modal/Modal';
import materialSelectorStore from '../../../store/MaterialSelectorStore';
import { withRouter } from 'react-router';
import MaterialOptionsGrid from './MaterialOptionsGrid';
import { text } from '../../../utils';
import TargetedMaterial from './TargetedMaterial';
import { mediaMediumMax, mediaSmallMax, mediaMediumMin } from '../../Styled/media';
import roomsStore from '../../../store/RoomsStore';
import MaterialCard from '../MaterialCard';
import themeStore from '../../../store/ThemeStore';
import Notification from '../../Notification/Notification';
import { Link } from 'react-router-dom';
import BundleState from '../../../store/enums/BundleState';
import Exclamation from '../../Icons/Exclamation';
import { EventAction } from '../../../services/EventAction';
import { EventCategory } from '../../../services/EventCategory';
import appStore from '../../../store/AppStore';
import Bundle from '../../../store/models/Bundle';
import offersStore from '../../../store/OffersStore';
import routingStore from '../../../store/RoutingStore';
import Material from '../../../store/models/Material';
import Button from '../../Button/Button';
import { FormattedTextSection } from '../../Styled/FormattedTextSection';
import InfoBox from '../../InfoBox/InfoBox';
import { marketplaceRooms } from '../../../store/enums/MarketplaceRoomEnum';
import { useTrackers } from '../../../hooks/useTrackers';
import { ButtonType } from '@groupbuilderoy/gb-components-library';
import AuxiliarButton from '../../../auxiliaries/AuxiliarButton';

const MaterialSelectorContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  @media ${mediaSmallMax} {
    max-height: none;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const MaterialOptions = styled.div`
  flex-grow: 1;
  padding: 2.5rem 2.5rem 0 5rem;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  @media screen and (max-width: 1200px) {
    padding: 2.5rem 2.5rem 0 2.5rem;
  }

  @media ${mediaMediumMax} {
    max-height: none;
  }

  @media ${mediaSmallMax} {
    padding: 0 1rem 1rem;
    margin-top: 1rem;
  }
`;

const StyledNotification = styled(Notification)`
  margin: 2.5rem 5rem 0 5rem;

  @media screen and (max-width: 1200px) {
    margin: 2.5rem 2.5rem 0 2.5rem;
  }

  @media ${mediaSmallMax} {
    margin: 1rem 0;
  }
`;

const NotificationContent = styled.div`
  width: 85%;

  & > p {
    margin-bottom: 1rem;

    @media ${mediaSmallMax} {
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 0;

      &.visible-on-desktop {
        display: none;
      }
    }
  }

  a {
    font-size: 15px;
  }

  @media ${mediaMediumMax} {
    width: 95%;
  }

  @media ${mediaSmallMax} {
    width: 100%;
  }
`;

const MaterialDescription = styled(FormattedTextSection)`
  margin-bottom: 1rem;
  align-self: flex-start;

  h2 {
    margin-bottom: 1rem;
  }

  &.visible-on-mobile {
    display: none;
    @media ${mediaSmallMax} {
      padding: 1rem 1rem 0 1rem;
      display: block;
    }
  }

  &:not(.visible-on-mobile) {
    display: none;
    @media ${mediaMediumMin} {
      display: block;
    }
  }
`;

const LockedMaterialRowTitle = styled.p`
  font-size: 20px;
  line-height: 60px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: black;
    width: 100%;
    content: '';
  }
`;

const LockedMaterialContainer = styled.div`
  max-width: 55%;

  @media ${mediaSmallMax} {
    max-width: 100%;
  }
`;

const IncludedInRooms = styled.p`
  margin-bottom: 1rem;
  align-self: flex-start;

  @media ${mediaSmallMax} {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

const isLocked = (bundle: Bundle) =>
  bundle.state === BundleState.LOCKED ||
  bundle.state === BundleState.DEADLINE_GONE ||
  bundle.state === BundleState.CONFIRMED;

const orderByName = (a: Material, b: Material) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const orderByPrice = (a: Material, b: Material) => {
  if (!a.totalCost || !b.totalCost || a.totalCost === b.totalCost) {
    return orderByName(a, b);
  }
  if (Number(a.totalCost) < Number(b.totalCost)) {
    return -1;
  }
  if (Number(a.totalCost) > Number(b.totalCost)) {
    return 1;
  }
  return 0;
};

const redirectToOffer = (b: Bundle) => {
  if (!!b.offers) {
    offersStore.setSelectedOffer(b.offers[0]);
  }
  routingStore.push('/order-confirmation');
};

const MaterialSelector = () => {
  const targetedMaterialRef = useRef<HTMLDivElement>(null);
  const { triggerEvent } = useTrackers();
  const bundle = materialSelectorStore.selectedBundle;
  const [targetedMaterialId, setTargetedMaterialId] = useState<number | null>(null);

  useEffect(() => {
    if (window.innerWidth < 768 && targetedMaterialRef?.current) {
      targetedMaterialRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  }, [targetedMaterialId]);

  useEffect(() => {
    setTargetedMaterialId(
      materialSelectorStore.targetedMatId
        ? materialSelectorStore.targetedMatId
        : bundle
        ? bundle.selectedMaterialOption?.projectMaterialId
        : 0
    );
  }, [bundle]);

  if (bundle) {
    const onTarget = (projectMaterialId: number | null) => {
      if (marketplaceRooms.includes(roomsStore.selectedRoom?.name || '')) {
        triggerEvent({
          action: EventAction.BROWSE_MARKETPLACE_MATERIAL_SELECTIONS,
          category: EventCategory.USER
        });
      } else {
        triggerEvent({
          action: EventAction.BROWSE_MATERIAL,
          category: EventCategory.USER
        });
      }

      materialSelectorStore.targetMaterial(projectMaterialId);
      setTargetedMaterialId(projectMaterialId || null);
    };

    const resetTargetedMaterial = (targetBundle: Bundle) => {
      materialSelectorStore.targetMaterial(
        targetBundle.selectedMaterialOption?.projectMaterialId || null
      );
      materialSelectorStore.resetTargetedMatId();
      if (targetBundle.selectedMaterialOption)
        setTargetedMaterialId(targetBundle.selectedMaterialOption?.projectMaterialId);
    };

    const {
      defaultMaterialOption,
      targetedMaterial,
      samePricedMaterials,
      differentPricedMaterials,
      deadline,
      deadlineAsDate,
      deadlineNear,
      selectedMaterial
    } = bundle;

    const isTargetedSelected = targetedMaterialId === selectedMaterial;

    const roomNames = bundle.rooms
      .filter((r) => !r.selected || !appStore.tenantSettings.customerJourney2_showRooms)
      .map((r) => r.name)
      .join(', ');

    samePricedMaterials.sort(orderByName);
    differentPricedMaterials.sort(orderByPrice);

    return (
      <Modal
        overflow='auto'
        onClose={() => {
          resetTargetedMaterial(bundle);
          materialSelectorStore.selectBundle(roomsStore.selectedRoomId, undefined);
        }}
      >
        <MaterialDescription className='visible-on-mobile'>
          <h2>{bundle.name}</h2>
          <p>{bundle.description}</p>
        </MaterialDescription>
        {deadline && deadlineNear && !isLocked(bundle) && (
          <StyledNotification
            color='var(--custom-colorNotif1)'
            withBorder
            icon={<Exclamation fill='white' />}
          >
            <NotificationContent>
              <p>
                {text('reminderOfDeadline', {
                  date: deadlineAsDate
                })}
              </p>
              <p className='visible-on-desktop'>
                {text('reminderDescription', {
                  date: deadlineAsDate
                })}
              </p>
              <Link to='/order-confirmation' className='font-medium'>
                {text('toTheConfirmationPage')}
              </Link>
            </NotificationContent>
          </StyledNotification>
        )}
        <MaterialSelectorContainer>
          {!isLocked(bundle) && (
            <MaterialOptions>
              <MaterialDescription>
                <h2>{bundle.name}</h2>
                <p>{bundle.description}</p>
                {bundle.isPartOfNonDraftOffer && appStore.integratedApp && (
                  <AuxiliarButton
                    type={ButtonType.outlined}
                    caption={text('seeOffer')}
                    onClick={() => redirectToOffer(bundle)}
                  />
                )}
                {bundle.isPartOfNonDraftOffer && !appStore.integratedApp && (
                  <Button onClick={() => redirectToOffer(bundle)}>{text('seeOffer')}</Button>
                )}
              </MaterialDescription>
              <>
                {bundle.rooms.length === 1 && appStore.organisationId === 'bonava' && (
                  <IncludedInRooms className='font-medium'>
                    {text('includedInRoom')}
                    <br />
                    <span className='font-light' style={{ fontSize: '16px' }}>
                      {bundle.rooms[0].name}
                    </span>
                  </IncludedInRooms>
                )}
                {bundle.roomIds.length > 1 && (
                  <IncludedInRooms className='font-medium'>
                    {text('includedAlsoInRooms')}
                    <br />
                    <span className='font-light' style={{ fontSize: '16px' }}>
                      {roomNames}
                    </span>
                  </IncludedInRooms>
                )}
                {appStore.tenantSettings.customerJourney2_showRooms &&
                  !!bundle.isGeneralAppearanceMaterial && (
                    <InfoBox
                      styles={{ marginBottom: '1rem' }}
                      title={''}
                      description={text('generalMaterialSelectionInfo')}
                    />
                  )}
              </>
              {defaultMaterialOption && (
                <MaterialOptionsGrid
                  title={
                    marketplaceRooms.includes(roomsStore.selectedRoom?.name || '')
                      ? ''
                      : themeStore.selectedTheme
                      ? text('themeDefaultOption', {
                          theme: themeStore.selectedTheme.name
                        })
                      : text('defaultOption')
                  }
                >
                  <>
                    <MaterialCard
                      onClick={() => onTarget(defaultMaterialOption.projectMaterialId || null)}
                      material={defaultMaterialOption}
                      targeted={targetedMaterialId === defaultMaterialOption.projectMaterialId}
                    />
                  </>
                </MaterialOptionsGrid>
              )}
              {!!samePricedMaterials.length && (
                <MaterialOptionsGrid title={text('optionsIncludedInThePrice')}>
                  {samePricedMaterials.map((m) => (
                    <MaterialCard
                      key={m.projectMaterialId || 'null'}
                      material={m}
                      onClick={() => onTarget(m.projectMaterialId || null)}
                      targeted={targetedMaterialId === m.projectMaterialId}
                    />
                  ))}
                </MaterialOptionsGrid>
              )}
              {!!differentPricedMaterials.length && (
                <MaterialOptionsGrid
                  title={
                    marketplaceRooms.includes(roomsStore.selectedRoom?.name || '')
                      ? text('options')
                      : text('extraCostOptions')
                  }
                >
                  {differentPricedMaterials.map((m) => (
                    <MaterialCard
                      key={m.projectMaterialId || 'null'}
                      material={m}
                      onClick={() => onTarget(m.projectMaterialId || null)}
                      targeted={targetedMaterialId === m.projectMaterialId}
                    />
                  ))}
                </MaterialOptionsGrid>
              )}
            </MaterialOptions>
          )}
          {targetedMaterial && isLocked(bundle) ? (
            <LockedMaterialContainer>
              <MaterialOptions>
                <MaterialDescription>
                  <h2>{bundle.name}</h2>
                  <p>{bundle.description}</p>
                </MaterialDescription>
                <LockedMaterialRowTitle>{text('confirmedMaterial')}</LockedMaterialRowTitle>
                <TargetedMaterial
                  onSelect={materialSelectorStore.selectTargetedMaterial}
                  material={
                    bundle.materialOptions.find(
                      (m) => m.projectMaterialId === targetedMaterialId
                    ) || bundle.selectedMaterialOption
                  }
                  locked={isLocked(bundle)}
                  selected={isTargetedSelected}
                />
              </MaterialOptions>
            </LockedMaterialContainer>
          ) : (
            <div ref={targetedMaterialRef}>
              <TargetedMaterial
                onSelect={() => {
                  if (marketplaceRooms.includes(roomsStore.selectedRoom?.name || '')) {
                    // In marketplace room, default selections are "no selection"
                    if (targetedMaterialId === bundle.defaultMaterialOption?.projectMaterialId) {
                      triggerEvent({
                        action: EventAction.SELECT_MARKETPLACE_NO_SELECTION,
                        category: EventCategory.USER
                      });
                    } else {
                      triggerEvent({
                        action: EventAction.SELECT_MARKETPLACE_ITEM,
                        category: EventCategory.USER
                      });
                    }
                  } else {
                    triggerEvent({
                      action: EventAction.SELECT_MATERIAL,
                      category: EventCategory.USER
                    });
                  }
                  materialSelectorStore.selectTargetedMaterial();
                }}
                material={
                  bundle.materialOptions.find((m) => m.projectMaterialId === targetedMaterialId) ||
                  bundle.selectedMaterialOption
                }
                locked={isLocked(bundle)}
                selected={isTargetedSelected}
              />
            </div>
          )}
        </MaterialSelectorContainer>
      </Modal>
    );
  } else return null;
};

export default (withRouter as any)(observer(MaterialSelector));
