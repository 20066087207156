import React from 'react';
import styled from 'styled-components/macro';
import { Header, HeaderContent, HeaderContentWrapper, HeaderBg } from './headerStyledComponents';
import { text } from '../../utils';
import CircleButton from '../Button/CircleButton';
import ArrowRight from '../Icons/ArrowRight';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import appStore from '../../store/AppStore';
import Button from '../Button/Button';
import lightBoxStore from '../../store/LightBoxStore';
import { mediaSmallMax } from '../Styled/media';
import { EventCategory } from '../../services/EventCategory';
import { EventAction } from '../../services/EventAction';
import { ButtonType, IconKeys } from '@groupbuilderoy/gb-components-library';
import HeaderText from '../HeaderText';
import { useTrackers } from '../../hooks/useTrackers';
import AuxiliarButton from '../../auxiliaries/AuxiliarButton';

const ContentWrapper = styled(HeaderContentWrapper)`
  color: white;

  h1 {
    margin-top: 5.5rem;
    height: 6.5rem;

    @media ${mediaSmallMax} {
      font-size: 24px;
      line-height: 26px;
      margin-top: 38px;
      height: unset;
    }
  }
`;

const StyledArrow = styled(ArrowRight)`
  fill: var(--custom-colorAccent);
  transform: translate(-50%, -50%) rotate(180deg);
  left: 45%;

  width: 10px;
  height: 18px;

  @media ${mediaSmallMax} {
    width: 5px;
    height: 9px;
  }
`;

const StyledCircleButton = styled(CircleButton)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;

  transform: rotate(180deg);

  @media ${mediaSmallMax} {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
  }
`;

const ReturnToMaterialsLink = styled(NavLink)`
  border: 0;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;

  &:hover {
    color: var(--custom-colorHover);
    ${StyledCircleButton} {
      background-color: var(--custom-colorHover);
    }
  }

  @media ${mediaSmallMax} {
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
`;

const OrderConfirmationHeaderLinks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media ${mediaSmallMax} {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;

  button {
    margin: 0 1rem;
    padding: 0.75rem 1.5rem;

    @media ${mediaSmallMax} {
      padding: 9px;
      margin: 0 0.5rem;
    }
  }

  @media ${mediaSmallMax} {
    margin-top: 1rem;

    a {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const OrderConfirmationHeader = ({
  heading,
  description,
  hideReturn = false
}: {
  heading?: string;
  description?: string;
  hideReturn?: boolean;
}) => {
  const { triggerEvent } = useTrackers();
  if (appStore.integratedApp) {
    return (
      <>
        <Header integratedCj2={appStore.integratedApp} style={{ marginBottom: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NavLink to={'/materials'} style={{ borderBottom: 'none', marginBottom: '-2rem' }}>
              {!hideReturn && (
                <AuxiliarButton
                  style={{ margin: '1rem 0' }}
                  type={ButtonType.outlined}
                  caption={text('returnToMaterials')}
                  startIcon={IconKeys.longArrowLeft}
                />
              )}
            </NavLink>
            <HeaderText
              heading={heading || text('orderConfirmationPageTitle')}
              description={description || text('confirmationGuidance')}
            />
          </div>
        </Header>
      </>
    );
  }
  return (
    <Header>
      <HeaderBg src={appStore.headerImg} alt='header' />
      <HeaderContent>
        <ContentWrapper>
          <h1>{heading || text('orderConfirmationPageTitle')}</h1>
        </ContentWrapper>
        <OrderConfirmationHeaderLinks>
          {!hideReturn && (
            <ReturnToMaterialsLink to='/materials'>
              <StyledCircleButton>
                <StyledArrow />
              </StyledCircleButton>
              {text('returnToMaterials')}
            </ReturnToMaterialsLink>
          )}
          <Buttons>
            {appStore.floorPlan &&
              appStore.tenantSettings.customerJourney2_showHeader &&
              (appStore.integratedApp ? (
                <AuxiliarButton
                  caption={text('floorPlan')}
                  onClick={() => lightBoxStore.open([appStore.floorPlan as string])}
                />
              ) : (
                <Button onClick={() => lightBoxStore.open([appStore.floorPlan as string])}>
                  {text('floorPlan')}
                </Button>
              ))}
            {appStore.apartment &&
              appStore.tenantSettings.customerJourney2_showHeader &&
              appStore.tenantSettings.gb4dIsActive !== false && (
                <a
                  style={{ border: 'none', alignSelf: 'center', display: 'flex' }}
                  href={appStore.apartment.threeDUrl}
                  onClick={() =>
                    triggerEvent({
                      category: EventCategory.USER,
                      action: EventAction.VIEW_IN_3D
                    })
                  }
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button>{text('viewIn3d')}</Button>
                </a>
              )}
          </Buttons>
        </OrderConfirmationHeaderLinks>
      </HeaderContent>
    </Header>
  );
};

export default observer(OrderConfirmationHeader);
