import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import OrderConfirmationInfo from '../../components/OrderConfirmationInfo/OrderConfirmationInfo';
import styled from 'styled-components/macro';
import ConfirmationPhases from '../../components/ConfirmationPhases/ConfirmationPhases';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import appStore from '../../store/AppStore';
import { observer } from 'mobx-react-lite';
import offersStore from '../../store/OffersStore';
import RejectedOffersSection from '../../components/OffersConfirmation/RejectedOffersSection';

const Content = styled.div<{ integratedCj2?: boolean }>`
  max-width: 1440px;
  background-color: ${({ integratedCj2 }) => (integratedCj2 ? 'none' : 'white')};
  padding: ${({ integratedCj2 }) => integratedCj2 && '0px 24px'};
  z-index: 100;
  margin: 0 auto;
  padding-bottom: 1.8rem;
  position: relative;
`;

const OrderConfirmation = () => {
  const { apartmentId } = appStore;
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const doFetch = async () => {
      setIsFetching(true);
      await materialSelectorStore.fetchBundles(undefined, undefined, false);
      setIsFetching(false);
    };

    if (apartmentId) {
      doFetch();
    }
  }, [apartmentId]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      {!appStore.integratedApp && <Header />}

      <Content integratedCj2={appStore.integratedApp}>
        <ConfirmationPhases hide={isFetching} />
        {offersStore.totallyRejectedOffers.length > 0 && <RejectedOffersSection />}
        {appStore.tenantSettings.customerJourney2_showFooter && <OrderConfirmationInfo />}
      </Content>
    </>
  );
};

export default observer(OrderConfirmation);
